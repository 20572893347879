@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .field {
    @apply bg-background w-full focus:outline-none text-xs font-medium leading-none text-gray-800 p-3;
  }
  .input {
    @apply px-2 border border-gray-100 text-sm rounded bg-gray-50 focus-visible:shadow-[0px_0px_5px_0px_rgba(0,0,0,0.2)] focus-visible:shadow-sky-400 focus-visible:outline-none focus-visible:border-sky-200;
  }
}
